.sv_main {
  div[data-name="file_upload"] {
    border-radius: var(--border-radii-regular);
    box-sizing: border-box;
    background: var(--white);
    box-shadow: var(--box-shadow);

    .sv_q_file {
      min-height: 25rem;
      position: relative;
      font-size: var(--fs-base);
      line-height: var(--lh-h4);

      .sv-file__decorator {
        display: flex;
        flex-direction: column;
        position: absolute;
        box-sizing: border-box;
        border: 1px dashed var(--border-line);
        justify-content: center;
        align-items: center;
        margin: var(--fs-h1);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &:before {
          text-align: center;
          content: "Dra och släpp en fil här eller klicka på knappen för att ladda upp fil. Maximal filstorlek: 10 MB.";
          color: var(--grey-dark);
          font-size: var(--fs-micro);
          margin-bottom: var(--sm-spacing);
        }

        .sv_q_file_choose_button {
          appearance: none;
          border: none;
          background: transparent;
          padding: var(--regular-spacing) var(--xl-spacing);
          color: var(--primary);
          border-radius: var(--border-radii-large);
          font-weight: var(--semibold-weight);
          font-style: normal;
          font-size: var(--fs-base);
          line-height: var(--lh-h4);
          outline: none;
          cursor: pointer;
        }
      }

      .sv_q_file_remove_button {
        text-align: center;
        position: absolute;
        right: 2.25rem;
        top: 2.25rem;
        z-index: 1;
      }

      .sv_q_file_preview {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        margin: auto;
        border: 0;
        height: fit-content;

        .sv_q_file_remove {
          display: none;
        }

        .sv_q_file_sign {
          a {
            font-size: var(--fs-micro);
            color: var(--text);
            line-height: 1;
            margin-top: var(--regular-spacing);
          }
        }

        img {
          display: block;
          width: 6rem;
          height: 6rem;
          object-fit: contain;
          background: var(--bg-white);

          @media screen and (max-width: 700px) {
            display: none;
          }
        }
      }

      div:nth-child(4) {
        display: flex;
        box-sizing: content-box;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        position: absolute;
        bottom: var(--lh-h2);
        left: var(--lh-h2);
      }
    }
  }
}
